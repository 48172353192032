import React from "react"
import { Link } from "gatsby"

import './layout.css'

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children, isWide }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div style={{
      marginLeft: `auto`,
      marginRight: `auto`,
      maxWidth: rhythm(35),
      padding: `${rhythm(1)} ${rhythm(3 / 4)}`,
    }}>
      <ul style={{margin: "0 auto", maxWidth: rhythm(24)}} className="header-nav">
        <li><Link to="/" activeClassName="active">Blog</Link></li>
        <li><Link to="/talks" activeClassName="active">Talks</Link></li>
        {/*<li><Link to="/projects" activeClassName="active">Projects</Link></li>*/}
      </ul>
      <div style={{
        margin: "0 auto",
        maxWidth: rhythm(isWide ? 35 : 24)
      }}>
        <header>{header}</header>
        <main>{children}</main>
      </div>
    </div>
  )
}

export default Layout
